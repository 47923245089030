import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

import DefaultLayout from "/opt/build/repo/site/src/components/episodeLayout.js";
export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component '" + name + "' was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <h2>{`Sammanfattning`}</h2>
    <p>{`I denna specialare intervjuar vi Jan Ainali, Codebase Steward på Foundation for Public Code. Vi får reda på allt från vilket djur som symboliserar Jan, till hur han hjälper offentliga verksamheter att jobba med öppen mjukvara.`}</p>
    <ul>
      <li parentName="ul">{`foss-north: `}<a parentName="li" {...{
          "href": "https://foss-north.se/2021/"
        }}>{`https://foss-north.se/2021/`}</a></li>
      <li parentName="ul">{`foss-north Community Events: `}<a parentName="li" {...{
          "href": "https://foss-north.se/2021/communityday.html"
        }}>{`https://foss-north.se/2021/communityday.html`}</a></li>
      <li parentName="ul">{`Jan Ainali: `}<a parentName="li" {...{
          "href": "https://ainali.com/"
        }}>{`https://ainali.com/`}</a></li>
      <li parentName="ul">{`WikipediaPodden: `}<a parentName="li" {...{
          "href": "https://wikipediapodden.se/"
        }}>{`https://wikipediapodden.se/`}</a></li>
    </ul>
    <h2>{`Meta`}</h2>
    <ul>
      <li parentName="ul">{`Trevlig Mjukvara väljer mastodon-instans?`}</li>
    </ul>
    <h2>{`Kontakta oss`}</h2>
    <ul>
      <li parentName="ul">{`Hemsida: `}<a parentName="li" {...{
          "href": "https://trevligmjukvara.se"
        }}>{`https://trevligmjukvara.se`}</a></li>
      <li parentName="ul">{`Mail: `}<a parentName="li" {...{
          "href": "mailto:kontakt@trevligmjukvara.se"
        }}>{`kontakt@trevligmjukvara.se`}</a></li>
      <li parentName="ul">{`YouTube: `}<a parentName="li" {...{
          "href": "https://www.youtube.com/channel/UCRVmpkj-XM6UhUCjGiL3hhQ"
        }}>{`Trevlig Mjukvara`}</a></li>
      <li parentName="ul">{`Twitter: `}<a parentName="li" {...{
          "href": "https://twitter.com/trevligmjukvara"
        }}>{`@trevligmjukvara`}</a></li>
      <li parentName="ul">{`Telegram: `}<a parentName="li" {...{
          "href": "https://t.me/trevligmjukvara"
        }}>{`Trevlig Mjukvara`}</a></li>
      <li parentName="ul">{`Mastodon: `}<a parentName="li" {...{
          "href": "https://fikaverse.club/@trevligmjukvara"
        }}>{`@trevligmjukvara`}</a></li>
      <li parentName="ul">{`Matrix: `}<a parentName="li" {...{
          "href": "https://matrix.to/#/%23TrevligMjukvara:matrix.org"
        }}>{`#TrevligMjukvara`}</a></li>
      <li parentName="ul">{`GitHub: `}<a parentName="li" {...{
          "href": "https://github.com/trevligmjukvara"
        }}>{`Trevlig Mjukvara`}</a></li>
      <li parentName="ul">{`Alex: `}<a parentName="li" {...{
          "href": "https://mastodon.online/@_alexander_"
        }}>{`@`}<em parentName="a">{`alexander`}</em></a></li>
      <li parentName="ul">{`Seb: `}<a parentName="li" {...{
          "href": "https://social.gelotte.dev/@seb"
        }}>{`@seb`}</a></li>
      <li parentName="ul">{`Seb på twitter: `}<a parentName="li" {...{
          "href": "https://twitter.com/Kikkusrikkus"
        }}>{`@Kikkusrikkus`}</a></li>
      <li parentName="ul">{`Donationer: `}<a parentName="li" {...{
          "href": "https://liberapay.com/TrevligMjukvara/donate"
        }}>{`https://liberapay.com/TrevligMjukvara/donate`}</a></li>
    </ul>
    <h2>{`Tack till`}</h2>
    <ul>
      <li parentName="ul">{`Ljudeffekter från `}<a parentName="li" {...{
          "href": "https://www.zapsplat.com/"
        }}>{`https://www.zapsplat.com/`}</a></li>
      <li parentName="ul">{`Musik från `}<a parentName="li" {...{
          "href": "https://filmmusic.io"
        }}>{`https://filmmusic.io`}</a><ul parentName="li">
          <li parentName="ul">{`Pixelland by Kevin MacLeod`}<ul parentName="li">
              <li parentName="ul">{`Link: `}<a parentName="li" {...{
                  "href": "https://filmmusic.io/song/4222-pixelland"
                }}>{`https://filmmusic.io/song/4222-pixelland`}</a></li>
            </ul></li>
          <li parentName="ul">{`NewsSting by Kevin MacLeod`}<ul parentName="li">
              <li parentName="ul">{`Link: `}<a parentName="li" {...{
                  "href": "https://filmmusic.io/song/4124-newssting"
                }}>{`https://filmmusic.io/song/4124-newssting`}</a></li>
            </ul></li>
          <li parentName="ul">{`Toccata and Fugue in D Minor by Kevin MacLeod`}<ul parentName="li">
              <li parentName="ul">{`Link: `}<a parentName="li" {...{
                  "href": "https://filmmusic.io/song/4533-toccata-and-fugue-in-d-minor"
                }}>{`https://filmmusic.io/song/4533-toccata-and-fugue-in-d-minor`}</a></li>
            </ul></li>
          <li parentName="ul">{`Beautiful World by Rafael Krux`}<ul parentName="li">
              <li parentName="ul">{`Link: `}<a parentName="li" {...{
                  "href": "https://filmmusic.io/song/5616-beautiful-world-"
                }}>{`https://filmmusic.io/song/5616-beautiful-world-`}</a></li>
            </ul></li>
          <li parentName="ul">{`Dreamy Flashback by Kevin MacLeod`}<ul parentName="li">
              <li parentName="ul">{`Link: `}<a parentName="li" {...{
                  "href": "https://filmmusic.io/song/3679-dreamy-flashback"
                }}>{`https://filmmusic.io/song/3679-dreamy-flashback`}</a></li>
            </ul></li>
          <li parentName="ul">{`Black Knight by Rafael Krux`}<ul parentName="li">
              <li parentName="ul">{`Link: `}<a parentName="li" {...{
                  "href": "https://filmmusic.io/song/5617-black-knight-"
                }}>{`https://filmmusic.io/song/5617-black-knight-`}</a></li>
            </ul></li>
          <li parentName="ul">{`Pixel Peeker Polka - Faster by Kevin MacLeod`}<ul parentName="li">
              <li parentName="ul">{`Link: `}<a parentName="li" {...{
                  "href": "https://filmmusic.io/song/4220-pixel-peeker-polka---faster"
                }}>{`https://filmmusic.io/song/4220-pixel-peeker-polka---faster`}</a></li>
            </ul></li>
          <li parentName="ul">{`Backbay Lounge by Kevin MacLeod`}<ul parentName="li">
              <li parentName="ul">{`Link: `}<a parentName="li" {...{
                  "href": "https://filmmusic.io/song/3408-backbay-lounge"
                }}>{`https://filmmusic.io/song/3408-backbay-lounge`}</a></li>
            </ul></li>
          <li parentName="ul">{`Heroic Adventure by Rafael Krux`}<ul parentName="li">
              <li parentName="ul">{`Link: `}<a parentName="li" {...{
                  "href": "https://filmmusic.io/song/5885-heroic-adventure"
                }}>{`https://filmmusic.io/song/5885-heroic-adventure`}</a></li>
            </ul></li>
          <li parentName="ul">{`Licens för alla: CC BY 4.0: `}<a parentName="li" {...{
              "href": "https://filmmusic.io/standard-license"
            }}>{`https://filmmusic.io/standard-license`}</a></li>
        </ul></li>
      <li parentName="ul">{`Grafik och font i loggan:`}<ul parentName="li">
          <li parentName="ul">{`Ok-emoji: emojione version 2.2.7, `}<a parentName="li" {...{
              "href": "https://commons.wikimedia.org/wiki/File:Emojione_1F44C.svg"
            }}>{`https://commons.wikimedia.org/wiki/File:Emojione_1F44C.svg`}</a>{` (CC BY 4.0)`}</li>
          <li parentName="ul">{`Font: Sulphur Point av Dale Sattler, `}<a parentName="li" {...{
              "href": "https://fonts.google.com/specimen/Sulphur+Point"
            }}>{`https://fonts.google.com/specimen/Sulphur+Point`}</a>{` (OFL)`}</li>
        </ul></li>
    </ul>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      